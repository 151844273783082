<template>
    <div class="services-offered-page-container">
        <div class='service-offered-header'>
            <div class='service-offered-header-container'>
                <h3 class="procedure-code-heading">Procedure Codes (CPT)</h3>
                <p class="">Select procedure codes and assign your self-pay fees.</p>
            </div>
        </div>
        <div class="service-offered-list-section">
            <div v-for="(service_fee, key) in service_fees" :key="service_fee.data_id" class="flex center single-service">
                <div class="multiselect-service-offered">
                    <label>Procedure code (CPT) </label>
                    <Multiselect v-model="service_fee.selected" :options="options" :key="'select_' + service_fee.data_id"
                        track-by="id" :custom-label="serviceCodeDesc" @input="() => {
                            $forceUpdate();
                        }
                            " @select="(e) => {
        options = calibrateMenu(e, service_fee.selected, 'id', options);
    }
        " :disabled="service_fee.disabled">
                    </Multiselect>
                </div>
                <div class="fee-service-offered">
                    <div>
                        <label>Enter Fee</label>
                        <CurrencyInput @currency="debounceHandleCurrencyChange($event, service_fee, 'cost')"
                            :id="'input_' + service_fee.data_id" :name="'input_' + service_fee.data_id"
                            :key="'input_' + service_fee.data_id" :initialValue="service_fee.cost" />
                    </div>
                </div>
                <div class="service-offered-action-buttons">
                    <button class="delete-button" @click="showDeleteAlertPopUp(key)">
                        <!-- <span class="material-icons-outlined" @click="removeCpt(key)">delete</span> -->
                        <div>
                            <span class="material-symbols-outlined">
                                delete
                            </span>
                        </div>
                        <div class="text">
                            <span>Delete</span>
                        </div>
                    </button>
                </div>
            </div>
            <!-- <button class="add-service_btn no-bg text-button" @click="addCpt()">
                <span class="material-icons purple">add_box</span> Add additional CPT code
            </button> -->
            <button class="primary text-button  inline-block add-additional-cpt" @click="addCpt()">
                <span class="rounded-add-custom material-icons-outlined">add</span>
                <span class="additional-counselor-text">Add additional CPT code</span>
            </button>
        </div>
        <!-- <div  style="border:2px solid green;width: 90%;">
            <div style="width:100%;border:1px solid; padding: 2rem;">
                <h3 class="dark-text margin-0">Procedure Codes (CPT)</h3>
                <p class="margin-0">Select procedure codes and assign your Self-Pay fees.</p>
            </div>
            <div v-for="(service_fee, key) in service_fees" :key="service_fee.data_id" class="flex center single-service">
                <div class="multiselect">
                    <label>Procedure code (CPT) </label>
                    <Multiselect v-model="service_fee.selected" :options="options" :key="'select_' + service_fee.data_id"
                        track-by="id" :custom-label="serviceCodeDesc" @input="
                            () => {
                                $forceUpdate();
                            }
                        " @select="
    (e) => {
        options = calibrateMenu(e, service_fee.selected, 'id', options);
    }
" :disabled="service_fee.disabled">
                    </Multiselect>
                </div>
                <div class="fee">
                    <label>Enter Fee</label>
                    <CurrencyInput @currency="debounceHandleCurrencyChange($event, service_fee, 'cost')"
                        :id="'input_' + service_fee.data_id" :name="'input_' + service_fee.data_id"
                        :key="'input_' + service_fee.data_id" :initialValue="service_fee.cost" />
                </div>
                <div class="delete-icon">
                    <span class="material-icons-outlined" @click="removeCpt(key)">delete</span>
                </div>
            </div>
            <button class="add-service_btn no-bg text-button" @click="addCpt()">
                <span class="material-icons purple">add_box</span> Add additional CPT code
            </button>
        </div> -->
        <DeleteProcedureCode v-if="showDeleteAlert" :open="showDeleteAlert" @cancel="showDeleteAlert = false"
            @handleDelete="handleDelete">

        </DeleteProcedureCode>
    </div>
</template>

<script>
/**
 * We want the option to be disabled from being selected if it's already selected,
 *
 */
import { debounce } from 'lodash';

const api_root = 'service_fees';
import CurrencyInput from '@/components/general/inputs/CurrencyInput';
import { generateId, calibrateMenu } from '@/util/genericUtilityFunctions';
import { serviceCodes } from '@/util/apiRequests';
import DeleteProcedureCode from './DeleteProcedureCode.vue';
export default {
    components: { CurrencyInput, DeleteProcedureCode, },
    name: 'CompanyServiceFees',
    data() {
        return {
            loading: 1,
            rows: [],
            level_id: 0,
            level: 'company',
            options: [],
            service_fees: [],
            justModified: null,
            originalOptions: [],
            calibrateMenu,
            financial_class: 'self_pay',
            insurance_payer_id: 0,
            showDeleteAlert: false,
            cptIndexToDelete: null
            // financial_classes : ['self_pay', 'self_pay_custom', 'commercial_insurance', 'medicaid', 'medicare', 'workers_comp'],
        };
    },
    computed: {
        header() {
            return this.headerText || this.pageMeta.title;
        }
    },
    methods: {
        async init() {
            this.level_id = this.$store.state.user.company_id;
            const res = await this.$api.get(`/${api_root}/list/company/${this.level_id}`);
            await this.getServiceCodeOptions();
            this.rows = res.data;
            this.service_fees = res.data
                .filter((fee) => fee.level === 'company')
                .filter((fee) => fee.code_type === 'service')
                .map((element) => {
                    element.selected = {
                        service_code: element.service_code,
                        long_descrip: element.long_descrip,
                        id: element.service_code_id
                    };
                    element.disabled = true;
                    return element;
                });
            this.options = this.options.filter((element) => {
                if (this.service_fees.some((fee) => element.id == fee.selected?.id)) {
                    return false;
                }
                return true;
            });
            this.loading = 0;
        },
        async handleDelete() {
            if (!this.cptIndexToDelete) {
                await this.removeCpt(this.cptIndexToDelete)
            }
            this.showDeleteAlert = false
        },
        async validate() {
            return 1;
        },
        addCpt() {
            //This is necessary due to the CurrencyInput being a bit glitchy for correctly binding.
            this.service_fees.push({
                data_id: generateId(),
                generated: true,
                selected: {},
                cost: 0,
                expected_reimbursement: 0,
                financial_class: this.financial_class,
                insurance_payer_id: this.insurance_payer_id
            });
        },
        serviceCodeDesc({ service_code, long_descrip }) {
            if (service_code && long_descrip) {
                return `${service_code} ${long_descrip}`;
            }
            return 'Select an option';
        },
        handleCurrencyChange(newCurrencyTotal, item, field) {
            this.$set(item, field, this.$getNumFromCurrency(newCurrencyTotal));
            this.update_setting(item);
        },
        debounceHandleCurrencyChange: debounce(function (newCurrencyTotal, item, field) {
            this.handleCurrencyChange(newCurrencyTotal, item, field);
        }, 400),
        showDeleteAlertPopUp(index) {
            let copy = this.service_fees[index];
            if (this.service_fees[index].generated == true) {
                this.service_fees.splice(index, 1);
                this.calibrateMenu(null, copy.selected, 'id', this.options);
            }
            else {
                this.showDeleteAlert = true
                this.cptIndexToDelete = index
            }
        },
        async removeCpt(index) {
            let copy = this.service_fees[index];
            if (this.service_fees[index].generated == true) {
                this.service_fees.splice(index, 1);
                this.calibrateMenu(null, copy.selected, 'id', this.options);
            } else {
                //Api Delete
                try {
                    //Refer to update_setting comment on why we use id_after_generated_is_false
                    await this.$api.delete(
                        `/${api_root}/${this.service_fees[index].id_after_generated_is_false
                            ? this.service_fees[index].id_after_generated_is_false
                            : this.service_fees[index].data_id
                        }`
                    );
                    this.service_fees.splice(index, 1);
                    this.options = this.calibrateMenu(null, copy.selected, 'id', this.options);
                    this.$toasted.success('Record deleted successfully.');
                } catch (error) {
                    this.$toasted.error('Failed to delete Record. Please try again later.');
                }
            }
        },
        async getServiceCodeOptions() {
            let result = await this.$api.get(serviceCodes.getServiceCodes());
            let options = result.data?.map((element) => {
                return { service_code: element.service_code, long_descrip: element.long_descrip, id: element.id };
            });
            this.options = options;
            this.originalOptions = this.options;
        },
        async update_setting(item) {
            try {
                if (Object.keys(item.selected).length <= 0) {
                    return;
                }
                //new
                if (item.generated == true) {
                    let result = await this.$api.post(
                        `/${api_root}/${this.level}/${this.level_id}/${item.selected.id}`,
                        { item }
                    );
                    this.$toasted.success('Record successfully created');

                    this.$set(item, 'generated', false);
                    //Apparently you can't do the below code commented out, because it will mess with the CurrencyInput by preventing a user from typing.
                    // this.$set(item, 'data_id',  result.data.recId[0]);

                    this.$set(item, 'id_after_generated_is_false', result.data.recId[0]);
                    this.options = this.calibrateMenu(item, null, 'id', this.options);
                    this.$set(item, 'disabled', true);
                }

                //update
                else {
                    let adata_id = 0;
                    if (item.id_after_generated_is_false) {
                        adata_id = item.id_after_generated_is_false;
                    } else {
                        adata_id = item.data_id;
                    }
                    await this.$api.put(`/${api_root}/${adata_id}`, { item });

                    this.$toasted.success('Successfully updated Record.');
                }
            } catch (err) {
                this.$toasted.error('Failed to update Record. Please try again later.');
            }
        }
    },
    async created() {
        await this.init();
    }
};
</script>

<!-- <style src="vue-multiselect/dist/vue-multiselect.min.css"></style> -->
